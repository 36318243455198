export const links = [
  {
    name: "Books",
    path: "/Books",
  },

  {
    name: "Bio",
    path: "/Bio",
  },

  {
    name: "Events",
    path: "/Events",
  },

  {
    name: "Press",
    path: "/Press",
  },
];

export const books = [
  {
    id: 1,
    title: "Satellite Boy",
    subtitle:
      "The International Manhunt for a Master Thief That Launched the Modern Communication Age",
    image: "Satellite",
  },
  {
    id: 2,
    title: "Satellite Boy",
    subtitle:
      "The International Manhunt for a Master Thief That Launched the Modern Communication Age",
    image: "Satellite",
  },
  {
    id: 3,
    title: "Satellite Boy",
    subtitle:
      "The International Manhunt for a Master Thief That Launched the Modern Communication Age",
    image: "Satellite",
  },

  {
    id: 4,
    title: "Satellite Boy",
    subtitle:
      "The International Manhunt for a Master Thief That Launched the Modern Communication Age",
    image: "Satellite",
  },
];
